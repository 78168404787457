import { useCallback, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useResizeDetector } from 'react-resize-detector';
import * as deviceDetector from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import moment from 'moment/moment';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/se';
import 'moment/locale/it';
import 'moment/locale/fi';
import 'moment/locale/nl';
import 'moment/locale/ro';
import 'moment/locale/pl';

import AlertToasts from 'pages/alerts/components/AlertToasts';
import Routes from './Routes';
import useUTMQueryStrings from 'hooks/useUTMQueryStrings';
import useServerSideEvents from 'hooks/useServerSideEvents';
import { useLocalStorage } from './hooks/useLocalStorage';

const AppContainer = styled(Container)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

const MobileAppWrapper = styled(Row)`
  @media (max-width: 992px) {
  }
`;

function App() {
  useUTMQueryStrings();
  useServerSideEvents();
  const [language, setLanguage] = useLocalStorage('lang', { lang: 'en' });
  // eslint-disable-next-line no-restricted-globals
  const parsedQueryString = queryString.parse(location.search);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const onResize = useCallback(({ width }) => {
    if (width <= 834) {
      deviceDetector.isMobile = true;
    } else {
      deviceDetector.isMobile = false;
    }
  }, []);

  useEffect(() => {
    moment.locale(currentLanguage); // Set moment locale
  }, [currentLanguage]);

  const { ref } = useResizeDetector({
    handleHeight: false,
    onResize
  });

  useEffect(() => {
    if (parsedQueryString?.lang) {
      setLanguage({
        lang: parsedQueryString?.lang
      });
    }
  }, [parsedQueryString?.lang, setLanguage]);

  useEffect(() => {
    i18n
      .changeLanguage(language?.lang ?? 'en')
      .then(console.log)
      .catch(console.error);
  }, [language, i18n]);

  return (
    <AppContainer fluid id="scrollable" ref={ref}>
      <MobileAppWrapper>
        <AlertToasts />

        <Container className="text-center mt-2 mb-2 min-h-100">
          <Routes />
        </Container>
      </MobileAppWrapper>
    </AppContainer>
  );
}

export default App;
